import styled from "styled-components";
import { motion } from "framer-motion";
import Header from "../components/header";
import Footer from "../components/footer";
import { useTheme } from '../ThemeContext';
import ThemeButton from "../components/themeToggle";
import ButtonComponent from "../components/button";
import StepCard from "../components/stepCard";
import step1 from "../images/step-1.png";
import step2 from "../images/step-2.png";
import step3 from "../images/step-3.png";
import { Link } from "react-router-dom";




const PageContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: ${({ theme }) => theme.color};
padding: 1%;
text-align: center;
height: 80vh;
width: 80%;
margin: 0 auto; 
@media (max-width: 768px) {
    padding: 1rem 1rem 3rem 1rem;
    width: 100%;
    height: auto;
}
`;



const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 4rem 0;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 2rem;
        width: 80%;
        padding: 2rem 0;
    }
`;





const Steps = () => {

    const { theme } = useTheme();
    const isMobile = window.innerWidth <= 768;

    return (
        <div>
            <PageContainer theme={theme}>
                    <CardsContainer>
                        <StepCard image={step1} title="Create a Project" description="Outline your project's details, goals, and funding requirements. Establish transparent rules for project-specific parameters: e.g payouts, token issuance, redemption etc. Building trust is important, so Pledgio ensures that all project rules are clear and immutable." />
                        <StepCard image={step2} title="Manage the Funds" description="Once the project gains traction and secures backing, it receives funding in ADA, Cardano's native cryptocurrency. It is possible to program customised payouts, token issuance, and redemptions directly on the Cardano blockchain." />
                        <StepCard image={step3} title="Build the Community" description="Reward supporters by issuing tokens or non-fungible tokens (NFTs) directly through Pledgio. Tokens can serve a multitude of purposes for community building including: governance participation, access to token-gated websites, or redemption for project-related rewards." />
                    </CardsContainer>
                    <Link to="/create-project">
                        <ButtonComponent 
                        theme={theme}
                        variant="primary" 
                        fontSize={'clamp(1rem, 1.2vw, 1.7rem)'}>Create a Project</ButtonComponent>
                    </Link>
                    {!isMobile && <ThemeButton />}
            </PageContainer>      
        </div>
    )

}

export default Steps;
