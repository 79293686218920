import styled from "styled-components";
import { motion } from "framer-motion";
import Header from "../components/header";
import Footer from "../components/footer";
import { useTheme } from '../ThemeContext';
import ThemeButton from "../components/themeToggle";
import ButtonComponent from "../components/button";
import InfoCard from "../components/infoCard";
import creators from "../images/creators.png";
import pledgers from "../images/pledgers.png";
import members from "../images/members.png";
import { Link } from "react-router-dom";




const PageContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: ${({ theme }) => theme.color};
padding: 1%;
text-align: center;
height: 80vh;
width: 80%;
margin: 0 auto; 
@media (max-width: 768px) {
    padding: 1rem 1rem 3rem 1rem;
    width: 100%;
    height: auto;
}
`;

const H1 = styled(motion.h1)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.7vw, 4rem);
    letter-spacing: 0.1rem;
    line-height: 3;
    font-weight: 200;
    color: var(--pledgio-peach);
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1.5rem, 3rem);
        letter-spacing: 0.05rem;
    }
`;

const H2 = styled(motion.h2)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.2vw, 2rem);
    letter-spacing: 0.1rem;
    font-weight: 100;
    line-height: 1;
    color: ${({ theme }) => theme.color}; 
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1.1rem, 2rem);
        letter-spacing: 0rem;
        line-height: 1;
        padding: 0.4rem 1rem;
    }
`;

const DescriptionContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`;


const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4rem;
    padding: 4% 0;
    width: 80%;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 2rem;
        padding: 2rem 0;
        width: 80%;
       
    }
`;




const HowItWorks = () => {

    const { theme } = useTheme();

    return (
        <div>
            <PageContainer theme={theme}>
                <DescriptionContainer>
                    <H1
                    initial={{ opacity: 0, scale: 0.7 }} 
                    animate={{ opacity: 0.9, scale: 1 }} 
                    transition={{  duration: 1, delay: 0 }}>How pledgio works</H1>
                    <H2
                    initial={{ opacity: 0, scale: 0.7 }} 
                    animate={{ opacity: 0.9, scale: 1 }} 
                    transition={{  duration: 0.8, delay: 0 }}>Pledgio is a crowdfunding platform built on the Cardano blockchain, designed to facilitate fast, secure, and transparent fundraising for projects of all sizes.</H2>
                </DescriptionContainer>
                    <CardsContainer>
                        <InfoCard image={creators} title="Creators" description="Crowdfund your project only when there is clear, community-backed demand." />
                        <InfoCard image={pledgers} title="Pledgers" description="Pledge your commitment to support a project, watch it launch, grow and receive rewards." />
                        <InfoCard image={members} title="Members" description="Become a pledgio member and participate in the start-up ecosystem around the world." />
                    </CardsContainer>
                    <Link to="/steps">
                        <ButtonComponent 
                        theme={theme}
                        variant="primary" 
                        fontSize={'clamp(1rem, 1.2vw, 1.7rem)'}>Find out more</ButtonComponent>
                    </Link>
            </PageContainer>
        </div>
    )

}

export default HowItWorks;
