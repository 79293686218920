import styled from "styled-components";
import { motion } from "framer-motion";
import Header from "../components/header";
import Footer from "../components/footer";
import { useTheme } from '../ThemeContext';
import pledgehog from "../images/pledgehog.png";
import ButtonComponent from "../components/button";
import { Link } from "react-router-dom";


const PageContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: ${({ theme }) => theme.background};
color: ${({ theme }) => theme.color};
text-align: center;
height: 80vh;
width: 80%;
margin: 0 auto; 
@media (max-width: 768px) {
    padding: 0rem;
    height: 66vh;
}
`;

const H1 = styled(motion.h1)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 2vw, 2rem);
    letter-spacing: 0.1rem;
    line-height: 1.5;
    font-weight: 100;
    color: ${({ theme }) => theme.color}; 
    @media (max-width: 768px) {
        font-size: clamp(1rem, 3vw, 2rem);
        letter-spacing: 0.05rem;
    }
    &::selection {
        color: black;
        background-color: var(--pledgio-peach);
      }
`;

const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    filter: ${({ theme }) => (theme.background === '#000' ? 'none' : 'invert(100%)')};
    `;


const Img = styled(motion.img)`
    width: 9vw;
    height: auto;
    margin: 2rem;
    @media (max-width: 768px) {
        width: 40%;
        height: auto;
        margin: 2rem 0rem 0rem 0rem;
    }
`;

const ButtonsContainer = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    margin-top: 2rem;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 2rem;
    }
`;

const Home = () => {


    const { theme } = useTheme();
    const isMobile = window.innerWidth <= 768;

    
    return (

        <div>
            <PageContainer theme={theme}>
                <H1 
                theme={theme}
                initial={{ opacity: 0, scale: 0.7 }} 
                animate={{ opacity: 0.9, scale: 1 }} 
                transition={{ duration: 1, delay: 0.2 }} >Transform innovative ideas into reality with the support of a community that validates and funds them.</H1>
                <ImgContainer theme={theme}>
                <Img
                initial={{ opacity: 0, scale: 0.5 }} 
                animate={{ opacity: 1, scale: 1 }} 
                transition={{ duration: 1, delay: 0 }}
                src={pledgehog}  alt="pledgehog" />
                </ImgContainer>
                <ButtonsContainer>
                <Link to="/projects">
                    <ButtonComponent variant="secondary" fontSize={'clamp(1rem, 1.3vw, 1.7rem)'}>Explore Projects</ButtonComponent>
                </Link>
                <Link to="/create-project">
                <ButtonComponent variant="primary" fontSize={'clamp(1rem, 1.3vw, 1.7rem)'}>Create Project</ButtonComponent>
                </Link>
                </ButtonsContainer>
            </PageContainer>
           
        </div>
 
    );
}

export default Home;