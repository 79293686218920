import { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { useTheme } from '../ThemeContext';
import ButtonPrimary from './button.jsx';
import { motion } from "framer-motion";
import PledgeTypeCard from "../components/pledgeTypeCard.jsx";
import optionA from "../images/pledge-option-a.png";
import optionB from "../images/pledge-option-b.png";
import optionC from "../images/pledge-option-c.png";
import Checkbox from '@mui/material/Checkbox';
import pledgedBadge from "../images/pedged-badge.png";
import Wallets from "../wallets.jsx";
import MailchimpForm from "./mailchimp.jsx";
import Xicon from "../images/x.png";

import CustomCircularProgress from './CustomCircularProgress'

import {MainClaimContext} from '../ClaimContext'

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'


const PopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 72vh;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    padding: 5%;
    border-radius: 17px;
    text-align: center;
    margin: 0 auto; 
    z-index: 1000;
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }
`;



const WalletsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
    gap: 1vw;
    color: ${({ theme }) => theme.color};
    border-radius: 17px;
    text-align: center;
    margin: 0 auto; 
    z-index: 1000;
    overflow-y: scroll;
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        gap: 1rem;
        padding: 0 5%;
    }
`;


const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 80vh;
    padding: 3%;
    @media (max-width: 768px) {
        justify-content: flex-start;
        padding: 0 3%;
        height: 100%;
    }

`;



const PledgeOptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 1vw;
    color: ${({ theme }) => theme.color};
    border-radius: 17px;
    text-align: center;
    z-index: 1000;
    overflow-y: scroll;
    @media (max-width: 768px) {
        flex-direction: column;
        height: 70vh;
        padding: 0 3%;
    }
`;



const SummaryWrapper = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  
    position: absolute;
    gap: 2rem;
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
        width: 90%;
        height: 100%;

    }
   
`;


const SummaryContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%; 
    color: ${({ theme }) => theme.color};
    @media (max-width: 768px) {
        width: 80%;
    
    }
`;

const DisclaimerContainer = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'FredokaMedium';
    font-size: clamp(0.8rem, 0.8vw, 1.5rem);
    letter-spacing: 0.1rem;
    font-weight: 100;
    padding: 3% 0;
    @media (max-width: 768px) {
        text-align: left;
    }
`;


const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%; 
    @media (max-width: 768px) {
        width: 90%;
    }
`;


const ConfirmationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    gap: 1%;
    background-color: black;
    @media (max-width: 768px) {
        justify-content: space-between;
        padding: 3%;
        overflow: hidden;
    }
`;



const ImageContainer = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13vw;
    height: auto;
    margin: 0 auto;
    contain: content;
    @media (max-width: 768px) {
        width: 63vw;
    }
`;


const Image = styled(motion.img)`
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
    padding: 1rem;
`;

const XShareButton  = styled(motion.button)`
    border: none;
    background-color: black;
    color: white;
    padding: 0.5rem 2rem;
    border-radius: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: 2px solid var(--pledgio-peach);
    img {
        width: 15px;
        height: auto;
        margin-right: 0.5rem;
    }
    a {
        color: white;
        text-decoration: none;
        font-family: 'FredokaMedium';
        font-size: clamp(0.7rem, 0.6vw, 2rem);
        letter-spacing: 0.1rem;
        @media (max-width: 768px) {
            font-size: clamp(0.9rem, 0.6vw, 2rem);
        }
    }
`;


const Form = styled(motion.form)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 1rem 0;
    input {
        padding: 1rem 2rem;
        width: 40%;
        border: none;
        outline: none;
        border-radius: 10rem;
        letter-spacing: 0.05rem;
        font-family: 'FredokaMedium';
        font-size: clamp(1rem, 1vw, 1.7rem);
    }
    label {
        color: var(--pledgio-peach);
    }
`;


const CloseButton = styled(motion.button)`
    position: absolute;
    top: 1%;
    left: 5%;
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    font-family: 'FredokaMedium';
    color: ${({ theme }) => theme.color};
`;


const H1 = styled(motion.h1)`
    font-family: 'FredokaMedium';
    font-size: clamp(0.7rem, 1.4vw, 2rem);
    letter-spacing: 0.2rem;
    line-height: 2;
    font-weight: 200;
    color: var(--pledgio-peach);
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1.2rem, 3rem);
        letter-spacing: 0.05rem;
        line-height: 1.5;
    }
`;

const H2 = styled(motion.h1)`
    font-family: 'FredokaMedium';
    font-size: clamp(0.8rem, 1.4rem, 2rem);
    letter-spacing: 0.1rem;
    line-height: 2;
    font-weight: 100;
    @media (max-width: 768px) {
        font-size: clamp(0.9rem, 0.8vw, 3rem);
        letter-spacing: 0.05rem;
        padding: 0;
        line-height: 1.5;
    }
`;


const P = styled(motion.p)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 0.9vw, 2rem);
    letter-spacing: 0.01rem;
    font-weight: 300;
    color: ${({ theme }) => theme.color};
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1rem, 2rem);
        letter-spacing: 0rem;
        line-height: 1;
        padding: 0.4rem 1rem;
    }
`;



const options = [
    { image: optionA, description: "Pledge your support for the project at no cost.", amount: 0},
    { image: optionB, description: "Receive a limited edition 'silver level' commemorative Pledgio NFT.", amount: 25},
    { image: optionC, description: "Receive a limited edition 'gold level' commemorative Pledgio NFT and a virtual high five. You rock!", amount: 50}
];


const PledgePopUp = ({ onClose }) => {

    const {isConnectOpen, setIsConnectOpen, activeWallet, stake_address} = useContext(MainClaimContext)

    const { theme } = useTheme();
    const [connected, setConnected] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const [confirmed, setConfirmed] = useState(false);
    const [submitted, setSubmitted] = useState(false)
    const [email, setEmail] = useState('');
    const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(false);
    const isMobile = window.innerWidth <= 768;





    async function postPledge(stake_address){
    const data = {'project':'pledgio','stake_address':stake_address}
    const response = await fetch('https://claymarket.io/pledgio/pledge/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()
}


    


    const paymentAddress = "addr1qydhdwlaq26ztc2l4zzmss745mk6s80yvlj3u7gpja849xhxr4qlnymdwdx325yt7t26h8qv4gkvhmul4l3mclzayrnqgmk7as"

    async function submitTransaction(cardano, signedTxHash){

            const submittedTx = await cardano.submitTx(signedTxHash)

            return submittedTx
        }


    async function getCurrentSlot(){
        let slot = await fetch('https://claymarket.io/api/current/slot/').then(response => response.json())
                                                                      .then(data => data)

        slot = parseInt(slot.slot)

        return slot
    }


      async function get_txBuilder(protocolParams){
  
           //console.log(protocolParams)

           const min_fee_a = String(protocolParams.min_fee_a)
           const min_fee_b = String(protocolParams.min_fee_b)
           const pool_deposit = String(protocolParams.pool_deposit)
           const key_deposit = String(protocolParams.key_deposit)
           const max_value_size = protocolParams.max_val_size
           const max_tx_size = protocolParams.max_tx_size
           const coins_per_utxo_word = String(protocolParams.coins_per_utxo_word)

           const linearFee = serLib.LinearFee.new(
               serLib.BigNum.from_str(min_fee_a),
               serLib.BigNum.from_str(min_fee_b)
           );


           const txBuilderCfg = serLib.TransactionBuilderConfigBuilder.new()
               .fee_algo(linearFee)
               .pool_deposit(serLib.BigNum.from_str(pool_deposit))
               .key_deposit(serLib.BigNum.from_str(key_deposit))
               .max_value_size(max_value_size)
               .max_tx_size(max_tx_size)
               .coins_per_utxo_word(serLib.BigNum.from_str(coins_per_utxo_word))
               .prefer_pure_change(false)
               .build();

           const txBuilder = serLib.TransactionBuilder.new(txBuilderCfg);
           return txBuilder
        }


    async function getUtxos(cardano){
      const txUnspentOutputs = serLib.TransactionUnspentOutputs.new()
        const utxos = (await cardano.getUtxos()).map((utxo) =>
            txUnspentOutputs.add(serLib.TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex")))
         );
        return txUnspentOutputs
    }


function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


async function getParameters(){
      const protocolParams = await fetch("https://claymarket.io/api/protocol/parameters/")
      const jsonBuildTransaction_params = await protocolParams.json()
      return jsonBuildTransaction_params
    }


function reset(){
    setSelectedOption(null)
    setConfirmed(false)
    setSubmitted(false)
}


async function buildTx(amount){

    let cardano = await window.cardano[activeWallet]
    cardano = await cardano.enable()


        let address_encoded = await cardano.getUsedAddresses()
        address_encoded = address_encoded[0]

        let address = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex")).to_bech32()


        let buyer = address 
        let buyer_encoded = serLib.Address.from_bech32(buyer)

        console.log(address)

        let receiver = paymentAddress
        let receiver_encoded = serLib.Address.from_bech32(receiver)

        const protocolParams = await getParameters()
        const currentSlot = await getCurrentSlot()

        // console.log(currentSlot)


        const ttl = parseInt(currentSlot) + 7200
        const utxos = await getUtxos(cardano)

        let lovelaceToSend = amount * 1000000

        let txBuilder = await get_txBuilder(protocolParams)

        let payment_output = serLib.TransactionOutput.new(
              receiver_encoded,
              serLib.Value.new(
                serLib.BigNum.from_str(String(lovelaceToSend))
              )
          )


            txBuilder.add_output(payment_output);


        // console.log('here2')


        try{
          txBuilder.add_inputs_from(utxos, 2) 
        }catch (err){
          // setTxStatus('error')
          // setStatusMessage('balance is not sufficient')
          // console.log('err')
          // console.log(err)

          reset()

          return
        }


        // console.log('here3')


        try{
          txBuilder.add_change_if_needed(buyer_encoded)
        }catch(err){
          // setTxStatus('error')
          // setStatusMessage('could not add change')

            reset()

          return
        }

        // console.log('here4')


        let unsignedTx;

        try{
           unsignedTx = txBuilder.build_tx()
        }catch(err){
          unsignedTx = ''
          // setTxStatus('error')
          // setStatusMessage('could not build transaction')
          reset()
          return
        }


        // console.log('here5')


        const unsignedTx_hash = Buffer.from(unsignedTx.to_bytes()).toString('hex')
        
        // setTxStatus('signing')
        let tx_witness;
        try{
            tx_witness = await cardano.signTx(unsignedTx_hash,true)
        }catch{
            // setTxStatus('error')
            // setStatusMessage('declined to sign')
            reset()
            return
        }

        // console.log('here6')

        const txBody = unsignedTx.body()

        let wallet_witness_set = serLib.TransactionWitnessSet.from_bytes(Buffer.from(tx_witness,"hex"))
        const totalVkeys = serLib.Vkeywitnesses.new();

        for (let i = 0; i < wallet_witness_set.vkeys().len(); i++){
          let holder_vkey = wallet_witness_set.vkeys().get(i)
            totalVkeys.add(holder_vkey)
        }


        const totalWitnesses = serLib.TransactionWitnessSet.new();
        totalWitnesses.set_vkeys(totalVkeys);


        let tx_auxiliary_data = unsignedTx.auxiliary_data()

        const signedTx = serLib.Transaction.new(
                    txBody,
                    totalWitnesses,
                    tx_auxiliary_data
            )

        const signedTxHash = Buffer.from(
                    signedTx.to_bytes()
            ).toString('hex')


        //setTxStatus('submitting')
        try{

          let status = await submitTransaction(cardano, signedTxHash)
          //setTxStatus('success')


        postPledge(stake_address)
        setSubmitted(true)

          
        }catch(err){
          // setTxStatus('fail')
          // setStatusMessage('failed to submit transaction')
          // console.log(err.message)
          reset()
        }

        // console.log('here7')

}








    const handleConnect = () => {
        setConnected(true);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(`Email submitted: ${email}`);
    };

    const handleConfirm = () => {
        if (isDisclaimerChecked) {

            setConfirmed(true);

            try{
                //submit here based on option
                if (selectedOption.amount == 0){

                    //just send a post request

                    postPledge(stake_address)

                    setSubmitted(true)

                }else if (selectedOption.amount == 25){

                    //send 10 ADA
                    //send post request

                    buildTx(25)

                    //setSubmitted(true)

                }else if (selectedOption.amount == 50){

                    //send 50 ADA
                    //send post request

                    buildTx(50)

                    //setSubmitted(true)
                }
            }catch{
                setSelectedOption(null)
                setSubmitted(false)
                setConfirmed(false)
            }

        } else {
            alert('Please check the disclaimer box in order to confirm your pledge.');
        }
      
    };

    const haddleDisclaimerChange = () => {
        setIsDisclaimerChecked(!isDisclaimerChecked);
    };


    const handleBackClick = () => {
        setSelectedOption(null);
    };

    const handleClosePopUp = () => {
        onClose();
    }
    

    return (
        <>

      

        <PopupContainer theme={theme}>
            {connected ? (
                selectedOption ? (
                    confirmed ? (


                        submitted? (

                        <ConfirmationContainer>
                            {isMobile && <CloseButton
                            initial={{ opacity: 0, scale: 0.9 }} 
                            animate={{ opacity: 0.9, scale: 1 }} 
                            transition={{ duration: 1, delay: 0 }}
                            theme={theme} 
                            onClick={handleClosePopUp} >X</CloseButton>}
                            <H1
                            initial={{ opacity: 0, scale: 0.7 }} 
                            animate={{ opacity: 0.9, scale: 1 }} 
                            transition={{ duration: 1, delay: 0.1 }}>Thank you!</H1>
                            <ImageContainer
                            initial={{ opacity: 0, scale: 0.7 }} 
                            animate={{ opacity: 0.9, scale: 1 }} 
                            transition={{ duration: 1, delay: 0.3 }}>
                                <Image src={pledgedBadge} alt="Pledged badge" />
                            </ImageContainer>
                            <XShareButton
                            initial={{ opacity: 0, scale: 0.7 }} 
                            animate={{ opacity: 0.9, scale: 1 }} 
                            transition={{ duration: 1, delay: 0.3 }}>
                                <img src={Xicon} alt="X icon" />
                                <a class="twitter-share-button"
                                href="https://twitter.com/intent/tweet?text=I+pledged+to+support+Pledgio+on&url=https://www.pledgio.xyz/"
                                data-size="large">
                                Share</a>
                            </XShareButton>
                            <Form 
                            onSubmit={handleSubmit}
                            initial={{ opacity: 0, scale: 0.7 }} 
                            animate={{ opacity: 0.9, scale: 1 }} 
                            transition={{ duration: 1, delay: 0.2 }}>
                                <label htmlFor="email">Join our mailing list to receive updates</label>
                                <MailchimpForm />
                            </Form>
                        </ConfirmationContainer>
                    ) : (

                        <CustomCircularProgress />

                    ))

                        : (
                    <SummaryWrapper
                    initial={{ opacity: 0, scale: 0.7 }} 
                    animate={{ opacity: 0.9, scale: 1 }} 
                    transition={{ duration: 1, delay: 0.1 }}>
                        <H1>Pledge summary</H1>
                        <SummaryContainer>
                            <H2>Amount</H2>
                            <H2>{selectedOption.amount} ADA</H2>
                        </SummaryContainer>
                        <SummaryContainer>
                            <H2></H2>
                            <H2>+ Transaction Fee</H2>
                        </SummaryContainer>
                        <SummaryContainer>
                            <H2>{selectedOption.description}</H2>
                        </SummaryContainer>
                        <DisclaimerContainer>
                        <Checkbox
                        size="medium"
                        checked={isDisclaimerChecked}
                        onChange={haddleDisclaimerChange}
                        sx={{
                            color: 'var(--pledgio-peach)',
                            '&.Mui-checked': {
                              color: 'var(--pledgio-peach)', // Change color when checked
                            },
                            '&:hover': {
                              color: 'var(--pledgio-peach)', // Change color on hover
                            },
                        }}/>
                        <P>I understand I am giving {selectedOption.amount} ADA in pledge of my support.</P>
                        </DisclaimerContainer>
                        <ButtonsContainer>
                        <ButtonPrimary variant="secondary" onClick={handleBackClick} fontSize={'clamp(1rem, 1vw, 1.7rem)'} >Back</ButtonPrimary>
                        <ButtonPrimary variant="primary" onClick={handleConfirm} fontSize={'clamp(1rem, 1vw, 1.7rem)'} disabled={!isDisclaimerChecked} >Confirm</ButtonPrimary>
                        </ButtonsContainer>
                    </SummaryWrapper>
                    ) 

                    

                ) : (
                <>
                <InnerContainer>
                    <PledgeOptionsContainer>
                            {options.map((option, index) => (
                                <PledgeTypeCard key={index} image={option.image} description={option.description} amount={option.amount} onClick={() => handleOptionSelect(option)} />
                            ))}
                    </PledgeOptionsContainer>
                    <H2
                    initial={{ opacity: 0, scale: 0.7 }} 
                    animate={{ opacity: 0.9, scale: 1 }} 
                    transition={{ duration: 1, delay: 0 }}>If the project doesn't get fully backed within the timeframe specified, funds are returned to the original wallet.</H2>
                </InnerContainer>
                </>
                )
            ) : (
                <>
                <WalletsContainer>
                <H1
                initial={{ opacity: 0, scale: 0.7 }} 
                animate={{ opacity: 0.9, scale: 1 }} 
                transition={{ duration: 1, delay: 0.1 }}>Connect your wallet to pledge your support for this project</H1>
                    {Wallets.map((wallet, index) => (
                        <ButtonPrimary variant="secondary" onClick={handleConnect} fontSize={'clamp(1rem, 1.1vw, 1.7rem)'} >{wallet}</ButtonPrimary>
                    ))}
                </WalletsContainer>
                </>
            )}
        </PopupContainer>
        </>
    );
};


export default PledgePopUp;

