import { Link } from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyles from '../GlobalStyles';
import { motion } from "framer-motion";




const MenuContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    padding: 2rem;`;

const MenuLink = styled(Link)`
    color: ${({ theme }) => theme.color};   
    text-decoration: none;
    margin: 0 4rem;
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.2vw, 2rem);
    font-weight: 400;
    letter-spacing: 0.07rem; 
    opacity: 0.9;
    transition: color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    &:hover {
        color: var(--pledgio-peach);
     
    }

    @media (max-width: 768px) {
        display: none;
    }

`;

const Menu = ({ theme }) => {
 
    return (
        <MenuContainer
        initial={{ opacity: 0, scale: 0.8 }} 
        animate={{ opacity: 1, scale: 1 }} 
        transition={{ duration: 1, delay: 0.}}>
            <GlobalStyles />
            <MenuLink theme={theme} to="/how-it-works">How it works</MenuLink>
            <MenuLink theme={theme} to="/projects">Projects</MenuLink>
            <MenuLink theme={theme} to="/about">About</MenuLink>
        </MenuContainer>
    );
}

export default Menu;