import { createContext, useState, useContext } from "react";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const [darkMode, setDarkMode] = useState(true);

    const toggleTheme = () => {
        setDarkMode(!darkMode);
        const updatedBackgroundColor = darkMode ? "#fff" : "#000";  
        const updatedTextColor = darkMode ? "#000" : "#fff";
        document.body.style.backgroundColor = updatedBackgroundColor;
        document.body.style.color = updatedTextColor;
        
    };

    const progressBarBackground = darkMode ? "rgba(217, 217, 217, 0.22)" : "#000";

    const theme = {
        background: darkMode ? "#000" : "#fff",
        color: darkMode ? "#fff" : "#000",
        progressBarBackground: darkMode ? "rgba(217, 217, 217, 0.22)" : "#000",
    };

    return (
        <ThemeContext.Provider value={{ darkMode, toggleTheme, theme }}>
            {children}
        </ThemeContext.Provider>
    );

};