import styled from "styled-components";
import { motion } from "framer-motion";



const CategoriesContainer = styled(motion.div)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 2rem;
    text-align: center;
    margin: 0 auto;
    gap: 2rem;
    @media (max-width: 768px) {
        gap: 0.5rem;
        margin: 0 auto;
        padding: 1rem 0.2rem 0rem 0.2rem;
    }
`;


const CategoryButton = styled(motion.button)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 0.8vw, 2rem);
    letter-spacing: 0.1rem;
    font-weight: 100;
    line-height: 1.5;
    padding: 0.7rem 1.8rem;
    border-radius: 1.2rem;
    outline: 2px solid var(--pledgio-peach);
    border: none;
    background-color: ${({ active }) => active ? 'var(--pledgio-peach)' : 'black'};
    transition: all 0.3s ease;
    color: ${({ active }) => active ? 'black' : 'white'};
    cursor: pointer;
    transition: background-color 0.2s; 
    @media (max-width: 768px) {
        font-size: clamp(0.8rem, 2vw, 2rem);
        letter-spacing: 0.02rem;
        padding: 0.5rem 0.7rem;
    }
    &:hover {
        background-color: var(--pledgio-peach);
        color: var(--background-dark);
    }
`;

const Categories = ({ activeCategory, handleCategoryChange, categories }) => {

    return (
        <CategoriesContainer
        initial={{ opacity: 0, scale: 0.7 }} 
        animate={{ opacity: 0.9, scale: 1 }} 
        transition={{ duration: 0.5, delay: 0 }}>
            {categories.map((category) => (
                <CategoryButton 
                    onClick={() => handleCategoryChange(category.name)}
                    key={category.name}
                    active={activeCategory === category.name}>
                    {category.name}
                    <span> ({category.count})</span>
                </CategoryButton>
            ))}
            </CategoriesContainer>
    );
}

export default Categories;