import './App.css';
import styled from "styled-components";
import GlobalStyles from './GlobalStyles.jsx';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from './pages/home.jsx';
import Projects from './pages/projects.jsx';
import HowItWorks from './pages/howItWorks.jsx';
import About from './pages/about.jsx';
import Project from './pages/project.jsx';
import Steps from './pages/steps.jsx';
import CreateProject from './pages/createProject.jsx';
import { projectData } from './projectData.jsx'
import { ThemeProvider } from './ThemeContext.jsx';
import { useTheme } from './ThemeContext';
import Header from "./components/header";
import Footer from "./components/footer";
import ThemeButton from "./components/themeToggle";


import {Provider} from './ClaimContext'


function Layout({ children, hideFooterMobile }) {

  const { theme } = useTheme();
  const isMobile = window.innerWidth <= 768;

  return (
    <div>
      <Header theme={theme} />
      {children}
      {!isMobile && <ThemeButton />}
      {!hideFooterMobile && <Footer theme={theme} />}
    </div>
  );
}

function App() {  

  const pledgio = projectData[0];

  return (
    <ThemeProvider>
    <Provider>
    <AnimatePresence mode='sync'>
      <Router>
          <Routes>
            <Route path='/' exact element={<Layout><Home /></Layout>} />
            <Route path='/projects' element={<Layout><Projects /></Layout>} />
            <Route path='/how-it-works' element={<Layout><HowItWorks /></Layout>} />
            <Route path='/about' element={<Layout><About /></Layout>} />
            <Route path='/steps' element={<Layout><Steps /></Layout>} />
            <Route path='/pledgio' element={<Layout hideFooterMobile={true} ><Project {...pledgio} /></Layout>} />
            <Route path='/create-project' element={<Layout><CreateProject /></Layout>} />
          </Routes>
      </Router>
    </AnimatePresence>
    </Provider>
    </ThemeProvider>
  );
}

export default App;
