import { useTheme } from "../ThemeContext";
import lightModeImg from "../images/light-mode.png";
import darkModeImg from "../images/dark-mode.png";
import { motion } from "framer-motion";
import styled from "styled-components";



const ButtonMode = styled(motion.button)`
    position: absolute;
    right: 2%;
    top: 13%;
    width: 5%;
    height: 5%;
    padding: 1%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-image: url(${({ darkMode }) => darkMode ? darkModeImg : lightModeImg }); 
    background-size: contain;
    border: none;
    outline: none;
    transition: background-image 0.2s; 
    cursor: pointer;
    @media (max-width: 1440px) and orientation: portrait {
        width: 70px;
        height: 40px;
        top: 10%;
        right: 5%;
    }
    @media (max-width: 768px) {
        width: 70px;
        height: 40px;
        position: relative;
    }
    `;

const ThemeButton = () => {

    const { darkMode, toggleTheme } = useTheme();

    return (
        <ButtonMode
        onClick={toggleTheme}
        darkMode={darkMode}
        initial={{ opacity: 0, scale: 0.8 }} 
        animate={{ opacity: 1, scale: 1 }} 
        transition={{ duration: 1, delay: 0.7 }}
        >
        </ButtonMode>
    );
}

    export default ThemeButton;