import React from 'react';
import GlobalStyles from '../GlobalStyles';
import styled from "styled-components";
import { motion } from "framer-motion";
import ButtonPrimary from './button';

function MailchimpForm() {


  const Button = styled(motion.input)`
    padding: 1rem 3rem;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 10rem;
    background-color: var(--pledio-peach);
    color: var(--text-light);
    margin-top: 2rem;

    font-family: 'FredokaBold';
    font-size: clamp(1rem, 1.2vw, 1.7rem);
    letter-spacing: 0.05rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      font-size: clamp(1rem, 3vw, 3rem);
    }

`;

const Input = styled(motion.input)`
    padding: 1rem 2rem;
    border: none;
    outline: none;
    margin: 0 auto;
    border-radius: 10rem;
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1vw, 1.7rem);
    letter-spacing: 0.05rem;
`;


  const [inputValue, setInputValue] = React.useState('')

const inputRef = React.useRef()


async function subscribeEmail(){

  let emailValue = inputRef.current.value

  let submit_url = 'https://claymarket.io/pledgio/subscribe/'

    const data = {email: emailValue}

    const response = await fetch(submit_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

}


  return (
    <div id="mc_embed_shell" style={{ width: '100%', display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
        <form action="https://xyz.us18.list-manage.com/subscribe/post?u=938369ee3b80065cdadf831bd&amp;id=fd8eacd253&amp;f_id=00c6c2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
          <div id="mc_embed_signup_scroll" style={{ width: '300%',position: 'relative', left: '50%', transform: 'translateX(-50%)', display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap:'1rem'}}>
            <div className="mc-field-group" style={{ display: 'flex', width: '100%', margin:"0 auto"}}>
              <Input ref={inputRef} placeholder="Email" type="email" name="EMAIL" className="required email" id="mce-EMAIL" required="" />
            </div>
            <div id="mce-responses" className="clear foot">
              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              <input type="text" name="b_938369ee3b80065cdadf831bd_fd8eacd253" tabIndex="-1" />
            </div>
                <ButtonPrimary
                  initial={{ opacity: 0, scale: 0.5 }} 
                  animate={{ opacity: 1, scale: 1 }} 
                  transition={{ duration: 1, delay: 1.2 }} 
                  fontSize={'clamp(1rem, 0.9vw, 1.7rem)'} 
                  type="submit" 
                  name="subscribe" 
                  id="mc-embedded-subscribe" 

                  onClick={()=>{
                    subscribeEmail()
                  }}

                  >Subscribe</ButtonPrimary>
                <p style={{ margin: '0 auto' }}>
                    <span style={{ display: 'inline-block', backgroundColor: 'transparent', borderRadius: '4px' }}>
                    </span>
                </p>
  
          </div>
        </form>
  
      <script src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js" type="text/javascript"></script>
      <script type="text/javascript" dangerouslySetInnerHTML={{__html: `
        (function($) {
          window.fnames = new Array(); 
          window.ftypes = new Array();
          fnames[0]='EMAIL';
          ftypes[0]='email';
        }(jQuery));
        var $mcj = jQuery.noConflict(true);
      `}}></script>
    </div>
  );
}

export default MailchimpForm;
