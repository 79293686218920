import { createGlobalStyle } from "styled-components";
import { styled } from "styled-components";
import { motion } from "framer-motion";



const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }


    body {
        --background-dark: #000000;
        --background-light: #f9f9f9;
        --text-dark: #FAF7F7;
        --text-light: #000000;
        --pledgio-peach: #FEBE98;

        font-family: Fredoka-Regular, FredokaSemiBold, FredokaMedium, FredokaBold;
        font-size: clamp(1rem, 3vw, 1.25rem);
        letter-spacing: 0.1rem;

        background-color: var(--background-dark);
        

    }
`;

export default GlobalStyles;