import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import Header from "../components/header";
import Footer from "../components/footer";
import { useTheme } from '../ThemeContext';
import ThemeButton from "../components/themeToggle";
import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import pledgeicon from "../images/pledge-heart.png";
import PledgePopUp from "../components/pledgePopup";
import InfoCard from "../components/infoCard";
/* Just for Pledgio */
import community from "../images/community-backed.png";
import trusted from "../images/trusted.png";
import fun from "../images/fun.png";
import creators from "../images/creators.png";
import pledgers from "../images/members.png";

import {MainClaimContext} from '../ClaimContext'



const PageContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: ${({ theme }) => theme.color};
padding: 1%;
text-align: center;
height: 80vh;
width: 80%;
margin: 0 auto; 
@media (max-width: 768px) {
    padding: 1rem ;
    width: 100%;

}
`;

const ProjectContainer = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 2%;
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0;
        width: 100%;
    }
`;

const ContainerLeft = styled(motion.div)`
    width: 20%;
    @media (max-width: 768px) {
        width: 100%;
        padding: 0rem 0;
        display: flex;
        flex-direction: column;
    }
`;

const ContainerRight = styled(motion.div)`
    width: 80%;
    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 2rem;
    }
`;

const HeaderContainer = styled(motion.div)`
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
    }
`;

const DescriptionContainer = styled(motion.div)`
    padding: 2% 4%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    height: 90%;
    @media (max-width: 768px) {
        padding: 2% 2%;
        overflow: hidden;
    }

`;

const H1 = styled(motion.h1)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.8vw, 3rem);
    letter-spacing: 0.1rem;
    font-weight: 200;
    color: ${({ theme }) => theme.color}; 
    &::selection {
        color: black;
        background-color: var(--pledgio-peach);
      }
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1.5rem, 3rem);
        letter-spacing: 0.05rem;
        padding: 0;
    }
`;

const H2 = styled(motion.h2)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1vw, 2rem);
    letter-spacing: 0.1rem;
    font-weight: 100;
    color: ${({ theme }) => theme.color}; 
    text-align: left;
    &::selection {
        color: black;
        background-color: var(--pledgio-peach);
      }
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1rem, 2rem);
        letter-spacing: 0rem;
        line-height: 1;
        padding: 0.4rem 1rem;
    }
`;

const H3 = styled(H2)`
color: var(--pledgio-peach);
font-size: clamp(1rem, 1.2vw, 2rem);
`;

const P = styled(motion.p)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1rem, 2rem);
    letter-spacing: 0.01rem;
    font-weight: 300;
    color: ${({ theme }) => theme.color}; 
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1rem, 2rem);
        letter-spacing: 0rem;
        line-height: 1;
        padding: 0.4rem 1rem;
    }
`;



const LogoContainer = styled(motion.div)`
    background-color: black;
    color: white;
    border-radius: 7%;
    outline: 2px solid var(--pledgio-peach);
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: width;
    margin: 0 auto;
    contain: content;
    @media (max-width: 768px) {
        width: 40%;
        height: width;
    }
`;

const Logo = styled(motion.img)`
width: 80%;
height: auto;
margin: 0 auto;
display: block;
padding: 1rem;
@media (max-width: 768px) {
    width: 90%;
    padding: 0.5%;
}
`;

const LinksContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    background-color: transparent;
    justify-content: space-between;
    padding: 0.5rem;
    gap: 0.8rem;
    transition: color 0.3s ease, color 0.3s ease;
    a {
        text-decoration: none;
        line-height: 1.5;
        font-size: clamp(1rem, 0.8vw, 2rem);
        color: ${({ theme }) => theme.color};
    }
    a:hover {
        color: var(--pledgio-peach);
    }
    @media (max-width: 768px) {
        a {
            font-size: clamp(0.9rem, 0.7vw, 2rem);
        }
    }
`;

const Tag = styled(motion.div)`
    font-family: 'FredokaMedium';
    background-color: ${({ active }) => active ? 'var(--pledgio-peach)' : 'black'};
    transition: all 0.3s ease;
    text-align: center;
    padding: 0.5rem 1.8rem;
    border-radius: 1.2rem;
    outline: 2px solid var(--pledgio-peach);
    width: 50%;
    margin: 0 auto; 
    a {
        text-decoration: none;
        color: white;
    }

`;

const TimeContainer = styled(Tag)`
    background-color: var(--pledgio-peach);
    color: black;
    wrap: nowrap;
    width: fit-content;
    padding: 0.5rem 1.2rem;
    max-width: 50%;
    p {
        font-size: clamp(1rem, 0.8vw, 2rem);
        color: black;
    }
    @media (max-width: 768px) {
        padding: 0.2rem 1rem;
    }
`;

const ProgressBarContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: transparent;
    padding: 1.2rem 1rem;
    @media (max-width: 768px) {
       order: 3;
    }
`;   

const ProgressBar = styled.progress`
appearance: none;
width: 90%;
height: 2.2rem;
margin: 0 0;
border-radius: 20px;
background-color: ${({ theme }) => theme.progressBarBackground};
overflow: hidden;
&::-webkit-progress-bar {
    background-color: transparent;
  }
  &::-webkit-progress-value {
    background-color: var(--pledgio-peach);
    border-radius: 20px;
  }
  &::-moz-progress-bar {
    background-color: grey; 
  }
  @media (max-width: 768px) {
    width: 80%;
    height: 33px;
    margin: 0rem 0;
  }
`;

const ProgressStatsContainer = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 85%;
    background-color: transparent;
    @media (max-width: 768px) {
        width: 80%;
    }
`;

const PledgeButton = styled.button`
    outline: 2px solid var(--pledgio-peach);
    border: none;
    padding: 1.2vw 0.5vw;
    border-radius: 12px;
    background-color: var(--pledgio-peach);
    max-width: 40%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'FredokaMedium';
    font-size: clamp(0.7rem, 1vw, 2rem);
    font-weight: 600;
    transition: color 0.3s ease, color 0.3s ease;
    img {
        filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(100deg) brightness(100%) contrast(100%);
    }
    @media (max-width: 768px) {
        width: 30%;
        padding: 1rem 0.5rem;
        color: black;
        margin: 0.7rem auto 0 auto;
        span {
            font-size: clamp(0.6rem, 1rem, 3rem);
        }
       }
    :hover {
        scale: 1.1;
    }

`;

const PledgeIcon = styled.img`
    width: 90%;
    height: auto;
`;


const materialTheme = createTheme({ 
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'black',
                    fontFamily: 'FredokaMedium',
                    fontSize: 'clamp(1rem, 1rem, 2rem)',
                    padding: '1rem',
                },
                arrow: {
                    color: 'black',
                }
            },
        },
    },
});

const TabsContainer = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    @media (max-width: 768px) {
        width: 100%;
    }

`;

const TabButton = styled(motion.button)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 0.8vw, 2rem);
    letter-spacing: 0.05rem;
    font-weight: 600;
    line-height: 1.5;
    padding: 0.7rem 1.8rem;
    border-radius: 1.2rem;
    outline: 2px solid var(--pledgio-peach);
    border: none;
    background-color: ${({ active }) => active ? 'var(--pledgio-peach)' : 'black'};
    transition: all 0.3s ease;
    color: ${({ active }) => active ? 'black' : 'white'};
    transition: background-color 0.2s; 
    cursor: pointer;
    &:hover {
        background-color: var(--pledgio-peach);
        color: black;
    }
    @media (max-width: 768px) {
        font-size: clamp(0.8rem, 0.8rem, 2rem);
        letter-spacing: 0.02rem;
        padding: 0.5rem 0.7rem;
        border-radius: 0.9rem;
    }
`;

const CardsContainer = styled(motion.div)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    gap: 7%;
    @media (max-width: 768px) {
        padding: 1rem 1rem;
        flex-direction: column;
    }
`;



const Project =  ({ image, title, tagline, pledgeMax, social, socialHandle, owner, overview, about }) => {


    const {isConnectOpen, setIsConnectOpen, activeWallet, stake_address} = useContext(MainClaimContext)


    const { theme } = useTheme();
    const [showPledgePopUp, setShowPledgePopUp] = useState(false);
    const [activeTab, setActiveTab] = useState('Overview');
    const isMobile = window.innerWidth <= 768;

    const handleOpenPopUp = () => {

        if (activeWallet == '' || activeWallet == undefined || activeWallet == null || stake_address == '' || stake_address == undefined || stake_address == null){
            setIsConnectOpen(true)
        }else{
            setShowPledgePopUp(true);
        }

        
    }

    const handleClosePopUp = () => {
        setShowPledgePopUp(false);
    };


    const [pledgeProgress, setPledgeProgress] = useState(0)

    const [timespan, setTimespan] = useState('-')


    async function getPledges(){

        let progress = 0 

        const pledgio_pledges = await fetch("https://claymarket.io/pledgio/pledges/"+title.toLowerCase()+"/")
        const json_pledgio_pledges = await pledgio_pledges.json()

        if (json_pledgio_pledges.hasOwnProperty('pledges')){
            progress = parseInt(json_pledgio_pledges['pledges'],10)
        }

        setPledgeProgress(progress)

    }



    async function getDaysLeft(){

        let progress = '0' 

        const pledgio_pledges = await fetch("https://claymarket.io/pledgio/daysleft/"+title.toLowerCase()+"/")
        const json_pledgio_pledges = await pledgio_pledges.json()

        if (json_pledgio_pledges.hasOwnProperty('days')){
            progress = json_pledgio_pledges['days']
        }

        if (progress < 0){
            progress = 0
        }

        setTimespan(progress)

    }


    useEffect(()=>{

        getPledges()
        const interval = setInterval(() => {
            getPledges()
        }, 10000);
 
        
        return () => clearInterval(interval);

    },[])


    useEffect(()=>{

        getDaysLeft()
        const interval = setInterval(() => {
            getDaysLeft()
        }, 10000);
 
        
        return () => clearInterval(interval);

    },[])
    

    return (
        <ThemeProvider theme={materialTheme}>
        <div>
            <PageContainer theme={theme}>
                <ProjectContainer theme={theme}>
                    <ContainerLeft
                     initial={{ opacity: 0, scale: 0.8 }} 
                     animate={{ opacity: 0.9, scale: 1 }} 
                     transition={{ duration: 1, delay: 0.1 }}>
                        <LogoContainer>
                            <Logo src={image} alt={title} />
                        </LogoContainer>   
                        <LinksContainer theme={theme}>
                                <a href={social}>@{socialHandle}</a>
                                <Tag theme={theme}>
                                <a href="https://app.gitbook.com/o/PGG8wyk4KR1wP9F7UocK/s/T6WZs0W4yG57uJwIFgH1/">Gitbook</a>
                                </Tag>
                                <Tooltip title={owner} arrow>
                                    <Tag theme={theme}>
                                    <a href="https://twitter.com/pledgio_xyz">Owner</a>
                                    </Tag>
                                </Tooltip>
                                <TimeContainer>
                                    <P>{timespan} days left</P>
                                </TimeContainer>
                            </LinksContainer>
                            <ProgressBarContainer>
                                <ProgressBar theme={theme} value={pledgeProgress} max={pledgeMax} />
                                <ProgressStatsContainer>
                                    <P>Pledged: {pledgeProgress}</P>
                                    <P>Goal: {pledgeMax}</P>
                                </ProgressStatsContainer>
                            </ProgressBarContainer>
                            <PledgeButton onClick={handleOpenPopUp} >
                                <PledgeIcon src={pledgeicon} alt="pledge icon" />
                                <span>Pledge</span>
                            </PledgeButton>
                    </ContainerLeft>

                    <ContainerRight>
                        <HeaderContainer
                        initial={{ opacity: 0, scale: 0.8 }} 
                        animate={{ opacity: 0.9, scale: 1 }} 
                        transition={{ duration: 0.5, delay: 0.1 }}>
                            <H1>{title}</H1>
                            <TabsContainer>
                            <TabButton onClick={() => setActiveTab('Overview')} active={activeTab === 'Overview'} >Overview</TabButton>
                            <TabButton onClick={() => setActiveTab('About')} active={activeTab === 'About'}>About</TabButton>
                            {/*<TabButton onClick={() => setActiveTab('Tokens')} active={activeTab === 'Tokens'}>Tokens</TabButton>*/}
                            </TabsContainer>
                        </HeaderContainer>  
                        <DescriptionContainer
                         initial={{ opacity: 0, scale: 0.8 }} 
                         animate={{ opacity: 0.9, scale: 1 }} 
                         transition={{ duration: 1, delay: 0.2 }}>
                            {activeTab === 'Overview' && (
                            <>
                                {overview.split('\n').map((line, index) => 
                                <H2 
                                initial={{ opacity: 0, scale: 0.8 }} 
                                animate={{ opacity: 1, scale: 1 }} 
                                transition={{ duration: 1, delay: 0.2}}
                                key={index}>{line}</H2>)}
                                <CardsContainer>
                                <InfoCard 
                                image={community} 
                                title="Liberate"
                                description="Advance projects with community verification."
                                />
                                <InfoCard 
                                image={trusted} 
                                title="Empower"
                                description="Enable creators to boldly pursue their visions with tangible supporter commitment."
                                />
                                <InfoCard 
                                image={fun} 
                                title="Elevate"
                                description="Promote a thriving ecosystem of varied projects."
                                />
                                </CardsContainer>
                           </>
                            )}
                            {activeTab === 'About' && (
                            <>
                                {about.split('\n').map((line, index) => 
                                <H2 
                                initial={{ opacity: 0, scale: 0.8 }} 
                                animate={{ opacity: 1, scale: 1 }} 
                                transition={{ duration: 1, delay: 0.2}}
                                key={index}>{line}</H2>)}

                                <div>

                                <H2 style={{fontWeight:'bold'}}
                                initial={{ opacity: 0, scale: 0.8 }} 
                                animate={{ opacity: 1, scale: 1 }} 
                                transition={{ duration: 1, delay: 0.2}}
                                >
                                So here’s what you need to do:
                                </H2>

                                <H2
                                initial={{ opacity: 0, scale: 0.8 }} 
                                animate={{ opacity: 1, scale: 1 }} 
                                transition={{ duration: 1, delay: 0.2}}
                                >
                                Familiarise yourself with the concept by navigating this website and exploring the details in the Gitbook (link).
                                </H2>

                                <br/>

                                <H2 style={{fontWeight:'bold'}}
                                initial={{ opacity: 0, scale: 0.8 }} 
                                animate={{ opacity: 1, scale: 1 }} 
                                transition={{ duration: 1, delay: 0.2}}
                                >
                                Learn by doing:
                                </H2>

                                <H2
                                initial={{ opacity: 0, scale: 0.8 }} 
                                animate={{ opacity: 1, scale: 1 }} 
                                transition={{ duration: 1, delay: 0.2}}
                                >
                                Click ‘Pledge’ if you’d like to show your support for the creation of this project.
                                Showing support can be free (think of this as an encouraging thumbs-up to the Pledgio team), or 25 or 50 ADA.

                                All ADA received will go directly into building the Pledgio platform.

                                Contributions of 25 and 50 ADA will be rewarded with a commemorative silver or gold Pledgio NFT, assuming our goal of 200 pledges is met. 

                                If our target of 200 pledges is not met, all funds (if applicable) will be returned to Pledgers, and we will take the idea back to the drawing board.
                                </H2>

                                </div>


                                <CardsContainer>
                                <InfoCard 
                                image={creators} 
                                title="Creators" 
                                description="Empowering creators to confidently pursue their visions with tangible supporter commitment, ensuring projects only move forward with verified demand."
                                summary="Issue tokens and offer rewards to pledgers when the project reaches or surpasses its pledging goal."
                                />
                                <InfoCard 
                                image={pledgers} 
                                title="Pledgers" 
                                description="Full-goal funding model empowers pledgers to participate in the growth and success of diverse projects, all while minimising their exposure to risk."
                                summary="If the project doesn't get fully backed within the timeframe specified, funds are returned to pledgers."
                                />
                                </CardsContainer>
                            </>
                            )}
                            {activeTab === 'Tokens' && (
                                <H2>Tokens coming soon</H2>
                            )}
                        </DescriptionContainer> 
                    </ContainerRight>
                    {!isMobile && <ThemeButton />}
                    {isMobile && <Footer theme={theme} />}
                </ProjectContainer> 
            </PageContainer>
            {showPledgePopUp && <PledgePopUp onClose={handleClosePopUp} />} 
            {!isMobile && <Footer theme={theme} />}
        </div>
        </ThemeProvider>
    )

}

export default Project;
