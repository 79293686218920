import styled from "styled-components";
import { m, motion } from "framer-motion";
import Header from "../components/header";
import Footer from "../components/footer";
import { useTheme } from '../ThemeContext';
import ThemeButton from "../components/themeToggle";
import ButtonComponent from "../components/button";
import { Link } from "react-router-dom";




const PageContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: ${({ theme }) => theme.background};
color: ${({ theme }) => theme.color};
padding: 2rem;
text-align: center;
height: 70vh;
width: 80%;
margin: 2rem auto; 
@media (max-width: 768px) {
    padding: 0rem;
    height: 60vh;
}
`;


const TextContainer = styled(motion.div)`
    width: 60%;
    max-width: 1100px;
    padding: 5rem 6rem;
    border-radius: 20px;
    align-items: center;
    text-align: center;
    background-color: var(--pledgio-peach);
    @media screen and (min-width: 2000px) {
        width: 60vw;
        padding: 5rem 9rem;
    }
    @media (max-width: 768px) {
        width: 100%;
        padding: 3rem 2rem;
    }
`;

const H1 = styled(motion.h1)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.3vw, 2rem);
    letter-spacing: 0.05rem;
    line-height: 1.5;
    font-weight: 100;
    color: black;
    &::selection {
    color: black;
    background-color: var(--pledgio-peach);
    } 
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1rem, 3rem);
        letter-spacing: 0.05rem;
        line-height: 1.2;
        padding: 0;
    }
`;

const H2 = styled(motion.h2)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 2vw, 10rem);
    letter-spacing: 0.1rem;
    font-weight: 100;
    line-height: 2;
    color:  var(--pledgio-peach);
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1.3rem, 2rem);
        letter-spacing: 0rem;
        line-height: 1;
        padding: 0.4rem 1rem;
    }
`;




const About = () => {

    const { theme } = useTheme();
    const isMobile = window.innerWidth <= 768;

    return (
        <div>
            <PageContainer theme={theme}>
                {!isMobile &&
                <H2 
                initial={{ opacity: 0, scale: 0.9}} 
                animate={{ opacity: 0.9, scale: 1 }} 
                transition={{ duration: 1, delay: 0 }}>Pledgio</H2>
                }
                <TextContainer
                 initial={{ opacity: 0, scale: 0.8}} 
                 animate={{ opacity: 0.9, scale: 1 }} 
                 transition={{ duration: 1, delay: 0.2 }}>
                    <H1
                    initial={{ opacity: 0, scale: 0.8 }} 
                    animate={{ opacity: 0.9, scale: 1 }} 
                    transition={{ duration: 1, delay: 0.5 }}>Welcome to Pledgio, where we’re dedicated to cultivating the growth of the Cardano ecosystem through a revitalised funding framework.<br></br><br></br>Our vision is simple yet powerful: to empower creators to manifest their boldest ideas while fostering a secure and transparent environment for backers.<br></br><br></br>At Pledgio, we’re committed to revolutionising crowdfunding with our innovative full-goal funding model and dedication to community-driven wins. </H1>
                </TextContainer>
            </PageContainer>      
        </div>
    )

}

export default About;
