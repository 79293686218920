import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useTheme } from '../ThemeContext';
import styled from "styled-components";
import pledgeicon from "../images/pledge-heart.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";


const CardWrapper = styled(motion.div)`
    margin: 0 auto;
    cursor: pointer;
    @media screen and (max-width: 1440px) {
        width: 80%;
    }
    @media (max-width: 768px) {
        margin: 0 auto;
        width: 100%;
        padding: 1rem 0rem;
    }
`;

const ProgressBar = styled.progress`
appearance: none;
width: 80%;
height: 2.5vh;
margin: 1rem 0;
border-radius: 20px;
background-color: rgba(217, 217, 217, 0.22);
overflow: hidden;
&::-webkit-progress-bar {
    background-color: transparent;
  }
  &::-webkit-progress-value {
    background-color: var(--pledgio-peach);
    border-radius: 20px;
  }
  &::-moz-progress-bar {
    background-color: grey; 
  }
  @media screen and (max-width: 1440px) {
    width: 70%;
    height: 3vh;
    }
  @media (max-width: 768px) {
    width: 80%;
    height: 15px;
    margin: 0rem 0;
  }
`;


const ProgressStats = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 80%;
    background-color: transparent;
    padding-bottom: 0.7rem;
    @media screen and (max-width: 1440px) {
        width: 70%;
        }
    @media (max-width: 768px) {
        width: 80%;
    }
`;


const PledgeButton = styled.button`
    outline: 2px solid var(--pledgio-peach);
    border: none;
    padding: 1.3rem 0.5rem;
    border-radius: 12px;
    background-color: transparent;
    width: 25%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: var(--pledgio-peach);
    }
    &:hover img {
        filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(100deg) brightness(100%) contrast(100%);
    }
    @media screen and (max-width: 1440px) {
        width: 25%;
    }
    @media (max-width: 768px) {
        width: 30%;
        padding: 0.7rem 0.5rem;
    }

`;

const PledgeIcon = styled.img`
    width: 90%;
    height: auto;
`;

export const StyledCardMedia = styled(CardMedia)`
&& {
    width: 30%;
    height: auto;
    max-width: 150px;
    max-height: 160px;
    objectFit: cover;
    margin: 1% auto 0 auto; 
    padding: 1.2rem;
    outline: 2px solid var(--pledgio-peach);
    border-radius: 10%;
    margin-top: 2%;
    @media screen and (max-width: 1440px) {
        width: 30%;
     }
    @media (max-width: 768px) {
        width: 40%;
        padding: 0.8rem 1rem;
    }
}
`;

const StyledCard = styled(Card)`
&& {
    background-color: black;
    color: white;
    border-radius: 7%;
    outline: 2px solid var(--pledgio-peach);
    padding: 4% 1%;
    @media screen and (max-width: 1440px) {
        padding: 3% 0%;
    }
    @media (max-width: 768px) {
        padding: 1rem;
    }
}
`;

const StyledTypography = styled(Typography)`
&& {
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.2vw, 2rem);
    letter-spacing: 0.1rem;
    font-weight: 100;
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1rem, 2rem);
        letter-spacing: 0rem;
    }
}
`;  

const StyledTypography2 = styled(Typography)`
&& {
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1rem, 2rem);
    letter-spacing: 0.01rem;
    font-weight: 100;
    opacity: 0.9;
    @media (max-width: 768px) {
        font-size: clamp(0.5rem, 0.7rem, 2rem);
        letter-spacing: 0rem;
    }
}
`;  
 


const ProjectCard = ({ image, title, description, tagline, pledgeMax }) => {

    
    const [pledgeProgress, setPledgeProgress] = useState(0)


    async function getPledges(){

        let progress = 0 

        const pledgio_pledges = await fetch("https://claymarket.io/pledgio/pledges/"+title.toLowerCase()+"/")
        const json_pledgio_pledges = await pledgio_pledges.json()

        if (json_pledgio_pledges.hasOwnProperty('pledges')){
            progress = parseInt(json_pledgio_pledges['pledges'],10)
        }
        
        setPledgeProgress(progress)

    }


    useEffect(()=>{

        getPledges()
        const interval = setInterval(() => {
            getPledges()
        }, 10000);
 
        
        return () => clearInterval(interval);

    },[])

    return (
        <Link to="/pledgio" style={{ textDecoration:"none", cursor: "none" }}>
        <CardWrapper
        initial={{ opacity: 0, scale: 0.8 }} 
        animate={{ opacity: 1, scale: 1 }} 
        transition={{ duration: 1, delay: 0 }}>
        <StyledCard>
            <StyledCardMedia    
                component="img"
                image={image}
                alt={title}
            />
            <CardContent>
                <StyledTypography>
                    {title}
                </StyledTypography>
                <Typography variant="body2"sx={{ fontFamily:"FredokaMedium", opacity: "0.8" }} color="white">
                {tagline}
                </Typography>
                <ProgressBar value={pledgeProgress} max={pledgeMax} />
                <ProgressStats>
                    <StyledTypography2 color="var(--pledgio-peach)">Pledged: {pledgeProgress}</StyledTypography2>
                    <StyledTypography2 variant="body2" color="white">Goal: {pledgeMax}</StyledTypography2>
                </ProgressStats>
                <PledgeButton>
                    <PledgeIcon src={pledgeicon} alt="pledge icon" />
                </PledgeButton>
            </CardContent>
        </StyledCard>
        </CardWrapper>
        </Link>
    );
}


export default ProjectCard;