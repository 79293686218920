import styled from "styled-components";
import { motion } from "framer-motion";
import { useTheme } from "../ThemeContext";



const Button = styled(motion.button)`

    background-color: ${({ variant }) => {
        switch (variant) {
            case 'primary':
                return 'var(--pledgio-peach)';
            case 'secondary':
                return '#000';
            default:
                return 'var(--pledgio-peach)';
        }
    }};

    color: ${({ variant }) => {
        switch (variant) {
            case 'primary':
                return '#000';
            case 'secondary':
                return '#fff';
            default:
                return '#000';
        }
    }};

    outline: ${({ variant }) => {
        switch (variant) {
            case 'primary':
                return '2px solid var(--pledgio-peach)';
            case 'secondary':
                return '2px solid var(--pledgio-peach)';
            default:
                return '2px solid var(--pledgio-peach)';
        }
    }};
    padding: 1rem 3rem;
    border: none;
    border-radius:  1.4rem;
    font-family: 'FredokaMedium';
    font-size: ${({ fontSize }) => fontSize};
    letter-spacing: 0.05rem;
    font-weight: 200;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: ${({ variant }) => {
            switch (variant) {
                case 'primary':
                    return 'var(--pledgio-peach)';
                case 'secondary':
                    return 'var(--pledgio-peach)';
                default:
                    return 'var(--pledgio-peach)';
            }
        }};
        color: ${({ variant }) => {
            switch (variant) {
                case 'primary':
                    return '#000';
                case 'secondary':
                    return '#000';
                default:
                    return '#000';
            }
        }};

    }

    @media (max-width: 768px) {
        padding: 0.8rem 2rem;
        font-size: clamp(1rem, 3vw, 3rem);
        border-radius:  1rem;
    }

`;

const ButtonPrimary = ({ children, onClick, variant, fontSize  }) => {
    return (
        <Button 
        onClick={onClick}
        variant={variant}
        fontSize={fontSize}
        initial={{ opacity: 0, scale: 0.5 }} 
        animate={{ opacity: 1, scale: 1 }} 
        transition={{ duration: 1, delay: 0.2 }}>
        {children}
        </Button>
    );
}

export default ButtonPrimary;

