import styled from "styled-components";
import whitelogo from '../images/pledgio-logo-white.png';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';


const LogoContainer = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    @media (max-width: 768px) {
        margin-top: 0rem;
        margin-left: 1.5rem;
    }
    filter: ${({ theme }) => (theme.background === '#000' ? 'none' : 'invert(100%)')};

`;

const LogoImage = styled.img`
    width: 15vw;
    max-width: 100%;
    height: auto;
    z-index: 100;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 40vw;
        height: auto;
    }
`;

const Logo = ({ theme }) => {

    return (
        <LogoContainer
        theme={theme}
        initial={{ opacity: 0, scale: 0.8 }} 
        animate={{ opacity: 1, scale: 1 }} 
        transition={{ duration: 0.5, delay: 0.5 }}>
            <Link to="/">
            <LogoImage src={whitelogo} alt="Pledgio Logo"  />
            </Link>
        </LogoContainer>
    );
}

export default Logo;