import styled from 'styled-components';
import { motion } from 'framer-motion';


const CardContainer = styled(motion.div)`
width: 100%;
max-width: 500px;
height: 100%;
outline: 2px solid var(--pledgio-peach);
border-radius: 2rem;
overflow: hidden;
margin: 0 auto;
padding: 2% 1%;
background-color: black;
align-items: center;
display: flex;
flex-direction: column;
justify-content: center;
@media (max-width: 768px) {
    padding: 7% 1%;
}
`;


const ImageContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
max-width: 11rem;
height: 10rem;
margin: 0 auto;
contain: content;
@media (max-width: 768px) {
    max-width: 10rem;
    height: 10rem;
}
`;



const Image = styled(motion.img)`
min-width: 70%;
max-height: 80%;
margin: 0 auto;
display: block;
padding: 0.5vw;
@media (max-width: 768px) {
    padding: 1vw;
    max-width: 50%;
    max-height: 90%;
}
`;

const Content = styled.div`
padding: 1rem 1rem;

`;


const H1 = styled(motion.h1)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.3vw, 2rem);
    letter-spacing: 0.1rem;
    padding: 0.4rem 1rem;
    font-weight: 200;
    color: var(--pledgio-peach);
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1.4rem, 3rem);
        letter-spacing: 0.05rem;
        padding: 0;
    }
`;

const H2 = styled(motion.h2)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 0.9vw, 2rem);
    letter-spacing: 0.1rem;
    font-weight: 100;
    color: white;
    line-height: 1;
    padding: 0rem 1rem 0 1rem;
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1rem, 2rem);
        letter-spacing: 0rem;
        line-height: 1;
        padding: 0rem 1rem;
    }
`;




const InfoCard = ({ image, title, description, summary }) => {

    return (
        <CardContainer
        initial={{ opacity: 0, scale: 0.7 }} 
        animate={{ opacity: 1, scale: 1 }} 
        transition={{ duration: 1, delay: 0}}>
            {image && 
            <ImageContainer>
                <Image src={image} alt={title} />
            </ImageContainer>
            }
            <Content>
                <H1>{title}</H1>
                <H2>{description}</H2>
                <H2>{summary}</H2>
            </Content>
        </CardContainer>
    );
};


export default InfoCard;