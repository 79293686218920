import styled from "styled-components";
import { motion } from "framer-motion";
import Header from "../components/header";
import Footer from "../components/footer";
import { useTheme } from '../ThemeContext';
import ThemeButton from "../components/themeToggle";
import Categories from "../components/categories";
import { useState } from "react";
import ProjectCard from "../components/card";
import { useSwipeable } from "react-swipeable";
import { projectData } from '../projectData';




const PageContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: ${({ theme }) => theme.color};
padding: 1%;
text-align: center;
height: 80vh;
width: 80%;
margin: 0 auto; 
@media (max-width: 768px) {
    width: 90%;
    height: 67vh;
    overflow-y: scroll;
    padding: 2% 0;
}
`;


const H1 = styled(motion.h1)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.5vw, 2rem);
    letter-spacing: 0.1rem;
    line-height: 1.5;
    font-weight: 200;
    color: var(--pledgio-peach);
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1.3rem, 3rem);
        letter-spacing: 0.05rem;
        padding: 0;
    }
`;

const H2 = styled(motion.h2)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.2vw, 2rem);
    letter-spacing: 0.1rem;
    font-weight: 100;
    color: ${({ theme }) => theme.color}; 
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1rem, 2rem);
        letter-spacing: 0rem;
        line-height: 1;
        padding: 0.4rem 1rem;
    }
`;

const ProjectsContainer = styled(motion.div)`
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    @media (max-width: 768px) {
        overflow-x: revert;
        padding: 1rem 0; 
    }

`;


const Arrow = styled(motion.button)`
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 10;
    margin: 0 1rem;
    background-color: transparent;
    color: ${({ theme }) => theme.color};
    font-size: 2rem;
    @media (max-width: 768px) {
        margin: 0 0.5rem;
    }
    `;

const ArrowLeft = styled(Arrow)`
    padding: 1rem;
    @media (max-width: 768px) {
        padding: 0;
    }
`;

const ArrowRight = styled(Arrow)`
    padding: 1rem;
    @media (max-width: 768px) {
        padding: 0;
    }
`;

const ErrorContainer = styled.div`
width: 100%;
max-width: 500px;
padding: 65% 0;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
@media (max-width: 768px) {
    width: 70%;
    padding: 76% 0;
}
`;





const Projects = () => {

    const isMobile = window.innerWidth <= 768;
    const [activeCategory, setActiveCategory] = useState('All');
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleCategoryChange = (category) => {
        setActiveCategory(category);
        setCurrentIndex(0);
    };

    const handleScrollRight = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex === filterProjects.length - 1) {
                return 0;
            } else {
                return prevIndex + 1;
            }
        });
    };
    
    const handleScrollLeft = () => {
        setCurrentIndex((prevIndex) => {
        if (prevIndex === 0) {
            return filterProjects.length - 1;
        } else {
            return prevIndex - 1;
        }
    });
    }

   const SwipeHandlers = useSwipeable({
         onSwipedRight: () => handleScrollRight(),
         onSwipedLeft: () => handleScrollLeft()
    });

    const categories = [
        {name: 'All', count: projectData.length},
        {name: 'Platform', count: projectData.filter(project => project.categories.includes('Platform')).length},
        {name: 'Event', count: projectData.filter(project => project.categories.includes('Event')).length},
        {name: 'DAO', count: projectData.filter(project => project.categories.includes('NFT')).length},
    ];

    const filterProjects = activeCategory === 'All' ? projectData : projectData.filter(project => project.categories.includes(activeCategory));

    const { theme } = useTheme();

    return (
        <div>
            
            <PageContainer theme={theme}>
                <H1 theme={theme}
                   initial={{ opacity: 0, scale: 0.7 }} 
                   animate={{ opacity: 0.9, scale: 1 }} 
                   transition={{ duration: 1, delay: 0}}>Explore Projects</H1>
                <Categories 
                activeCategory={activeCategory} 
                handleCategoryChange={handleCategoryChange}
                categories={categories} />
                <ProjectsContainer id="projects" {...SwipeHandlers} >
                    <ArrowLeft 
                    theme={theme} 
                    onClick={handleScrollLeft}
                    initial={{ opacity: 0, scale: 0.8 }} 
                    animate={{ opacity: 1, scale: 1 }} 
                    transition={{ duration: 1, delay: 1}}>&#10094;</ArrowLeft>
                    {filterProjects.length === 0 ? (
                        <ErrorContainer>
                            <H2 theme={theme}>No projects found</H2>
                        </ErrorContainer>
                    ) : (
                        <ProjectCard
                        image={filterProjects[currentIndex].image}
                        title={filterProjects[currentIndex].title}
                        tagline={filterProjects[currentIndex].tagline}
                        pledgeProgress={filterProjects[currentIndex].pledgeProgress}
                        pledgeMax={filterProjects[currentIndex].pledgeMax} />
                    )}
                        <ArrowRight 
                        theme={theme} 
                        onClick={handleScrollRight}
                        initial={{ opacity: 0, scale: 0.8 }} 
                        animate={{ opacity: 1, scale: 1 }} 
                        transition={{ duration: 1, delay: 1}}>&#10095;</ArrowRight>
                </ProjectsContainer>
                {!isMobile && <ThemeButton />}
            </PageContainer>
            

        </div>
    );
}

export default Projects;