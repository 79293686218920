import styled from "styled-components";
import x from "../images/x.png";
import { motion } from "framer-motion";
import { useTheme } from '../ThemeContext';



const FooterContainer = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    bottom: 0;
    position: absolute;
    padding: 1rem;
    background-color: transparent;
    color: ${({ theme }) => theme.color};
    @media (max-width: 768px) {
        padding: 0.5rem;
        bottom: 0; 
        position: relative;
    }
    `;

const SocialsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    filter: ${({ theme }) => (theme.background === '#000' ? 'none' : 'invert(100%)')};
    `;

const SocialIcon = styled.img`
    display: block;
    width: 1vw;
    height: auto;
    padding-bottom: 1rem;
    @media (max-width: 768px) {
        width: 4vw;
        height: auto;
        padding-bottom: 0.5rem;
    }
`;

const P = styled.p`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 0.7vw, 1.4rem);
    letter-spacing: 0.07rem; 
    opacity: 0.9;
    color: ${({ theme }) => theme.text};    
    @media (max-width: 768px) {
        font-size: clamp(0.8rem, 2vw, 2rem);
        letter-spacing: 0.02rem; 
    }
`;


const Footer = ({ theme }) => {

    return (
        <FooterContainer theme={theme}
        initial={{ opacity: 0, scale: 0.5 }} 
        animate={{ opacity: 1, scale: 1 }} 
        transition={{  duration: 1, delay: 0.5 }}>
            <SocialsContainer theme={theme}>
                <a href="https://twitter.com/pledgio_xyz" target="_blank" rel="noreferrer">
                    <SocialIcon src={x} alt="x socials" />
                </a>
            </SocialsContainer>
            <P theme={theme}>Pledgio © 2024. All rights reserved.</P>
        </FooterContainer>
    );
}

export default Footer;