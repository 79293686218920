import styled from "styled-components";
import { m, motion } from "framer-motion";
import Header from "../components/header";
import Footer from "../components/footer";
import { useTheme } from '../ThemeContext';
import ThemeButton from "../components/themeToggle";
import ButtonComponent from "../components/button";
import { Link } from "react-router-dom";



const PageContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: ${({ theme }) => theme.background};
color: ${({ theme }) => theme.color};
text-align: center;
height: 80vh;
width: 80%;
margin: 0 auto; 
@media (max-width: 768px) {
    padding: 0rem;
    height: 66vh;
}
`;

const H2 = styled(motion.h2)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 2vw, 10rem);
    letter-spacing: 0.1rem;
    font-weight: 100;
    line-height: 2;
    color:  var(--pledgio-peach);
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1.4rem, 2rem);
        letter-spacing: 0rem;
        line-height: 1;
        padding: 0.4rem 1rem;
    }
`;




const CreateProject = () => {

    const { theme } = useTheme();
    const isMobile = window.innerWidth <= 768;

    return (
        <div>
            <PageContainer theme={theme}>
                <H2 
                initial={{ opacity: 0, scale: 0.7}} 
                animate={{ opacity: 0.9, scale: 1 }} 
                transition={{ duration: 1, delay: 0 }}>Coming Soon</H2>
            </PageContainer>      
            {!isMobile && <ThemeButton />}
        </div>
    )

}

export default CreateProject;
