import styled from 'styled-components';
import { motion } from 'framer-motion';


const CardContainer = styled(motion.div)`
width: 100%;
max-width: 500px;
height: 40vh;
outline: 2px solid var(--pledgio-peach);
border-radius: 10%;
overflow: hidden;
margin: 0 auto;
padding: 3rem 3rem 0rem 3rem;
background-color: black;
display: flex;
flex-direction: column;
@media (max-width: 768px) {
    padding: 2rem 2rem;
}
`;


const ImageContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 15%;
height: 15%;
border-radius: 10%;
margin: 0 auto;
contain: content;
`;



const Image = styled(motion.img)`
width: 100%;
height: auto;
margin: 0 auto;
display: block;
`;



const H1 = styled(motion.h1)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.2vw, 2rem);
    letter-spacing: 0.1rem;
    line-height: 3;
    font-weight: 200;
    color: var(--pledgio-peach);
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1.4rem, 3rem);
        letter-spacing: 0.05rem;
        padding: 0;
    }
`;

const H2 = styled(motion.h2)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1vw, 2rem);
    letter-spacing: 0.1rem;
    font-weight: 100;
    color: white;
    opacity: 0.9;
    line-height: 1;
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1rem, 2rem);
        letter-spacing: 0rem;
        line-height: 1;
       
    }
`;




const StepCard = ({ image, title, description }) => {

    return (
        <CardContainer
        initial={{ opacity: 0, scale: 0.7 }} 
        animate={{ opacity: 0.9, scale: 1 }} 
        transition={{ duration: 0.7, delay: 0 }}>
            {image && 
            <ImageContainer>
                <Image src={image} alt={title} />
            </ImageContainer>
            }
            <H1>{title}</H1>
            <H2>{description}</H2>
    
        </CardContainer>
    );
};


export default StepCard;