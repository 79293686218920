import styled from 'styled-components';
import Logo from '../components/logo.jsx';
import Menu from '../components/menu.jsx';
import ButtonPrimary from './button.jsx';
import ThemeButton from './themeToggle.jsx';
import GlobalStyles from '../GlobalStyles.jsx';
import MobileMenu from "./mobileMenu.jsx";
import Wallets from "../wallets.jsx";
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';


import ConnectHandler from '../ConnectHandler'

import {useContext} from 'react'
import {MainClaimContext} from '../ClaimContext'


const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem 0.2rem 2rem;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    @media (max-width: 768px) {
        padding: 1rem 1rem 0rem 1rem;
        flex-direction: row;
    }
`;


const WalletOverlay = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.background};
    width: 77%;
    height: 77vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    border-radius: 2rem;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    contain: content;
    overflow-y: auto;
    transition: opacity 0.3s ease, visibility 0.3s ease;
`;


const WalletsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    flex-direction: column;
    background-color: transparent;
    margin: 0 auto;
    padding: 2rem;
    overflow-y: auto;
  
`;


const H1 = styled(motion.h1)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.5vw, 2rem);
    letter-spacing: 0.2rem;
    line-height: 2;
    font-weight: 200;
    color: var(--pledgio-peach);
    @media (max-width: 768px) {
        font-size: clamp(1rem, 1.4rem, 3rem);
        letter-spacing: 0.05rem;
        padding: 0;
    }
`;

const CloseButton = styled(motion.button)`
    position: absolute;
    top: 3%;
    left: 3%;
    background-color: transparent;
    border: none;
    font-size: 2.2rem;
    cursor: pointer;
    font-family: 'FredokaMedium';
    color: ${({ theme }) => theme.color};
`;


function getDisplayAddress(stake_address){

    let displayAddress = ''

    try{
        displayAddress = stake_address.slice(0,6) + '...' + stake_address.slice(-6,)
    }catch{

    }

    return displayAddress

}

function DisplayedAddress(props) {

    const {stake_address} = props

    const [displayAddress, setDisplayAddress] = useState('')

    useEffect(() => {

        let temp_displayAddress = getDisplayAddress(stake_address)

        setDisplayAddress(temp_displayAddress)

    },[stake_address])

    return(
        displayAddress
    )

}


const Header = ({ theme }) => {

    const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset, isConnectOpen, setIsConnectOpen} = useContext(MainClaimContext)


    const isMobile = window.innerWidth <= 768;
    const [ isOpen, setIsOpen ] = useState(false);
    const toggleWalletsOverlay = () => {
        setIsConnectOpen(!isConnectOpen);
    };


    const [isBusy, setIsBusy] = useState(false)
    const [updateWallet, setUpdateWallet] = useState()
    const [walletToEnable, setWalletToEnable] = useState()

    return (
        <HeaderContainer theme={theme}>
            <GlobalStyles />
            <MobileMenu />
            <Logo theme={theme} />
            <Menu theme={theme} />
            {isMobile && <ThemeButton />}
            {!isMobile && <ButtonPrimary variant="secondary" onClick={toggleWalletsOverlay} fontSize={'clamp(1rem, 1.3vw, 1.7rem)'} >

                {activeWallet != '' && activeWallet != undefined ? <DisplayedAddress stake_address={stake_address} /> : 'Connect'}

            </ButtonPrimary>}

            <ConnectHandler 
                  isBusy={isBusy}
                  setIsBusy={setIsBusy}
                  updateWallet={updateWallet}
                  setUpdateWallet={setUpdateWallet}

                  walletToEnable={walletToEnable}
                  setWalletToEnable={setWalletToEnable}
                />


            {isConnectOpen && (
            <WalletOverlay theme={theme}>
                {/*<CloseButton
            initial={{ opacity: 0, scale: 0.9 }} 
            animate={{ opacity: 0.9, scale: 1 }} 
            transition={{ duration: 1, delay: 0.2 }} 
            onClick={setIsOpen(false)} 
            theme={theme}>X</CloseButton>*/}
                <WalletsContainer>


                

                

                {Wallets.map((wallet, index) => (
                    <ButtonPrimary variant="secondary" fontSize={'clamp(1rem, 1.1vw, 1.7rem)'} 
                        onClick={()=>{
                            console.log(wallet)
    
                            if (wallet.toLowerCase() == 'gero'){
                                setIsBusy(true)
                                setWalletToEnable('gerowallet') 
                                setIsConnectOpen(false)
                            }else if (wallet.toLowerCase() == 'typhon'){
                                setIsBusy(true)
                                setWalletToEnable('typhoncip30')
                                setIsConnectOpen(false)
                            }else{
                                setIsBusy(true)
                                setWalletToEnable(wallet.toLowerCase()) 
                                setIsConnectOpen(false)
                            }

                        }}>
                    {wallet}</ButtonPrimary>
                ))}
                    </WalletsContainer>
            </WalletOverlay>
            )}
        </HeaderContainer>
    );
}

export default Header;