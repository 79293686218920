import Logo from "./images/hedgehog.png";


export const projectData = [
    {
        image: Logo,
        title: "Pledgio",
        tagline: "Crowfunding platform",
        pledgeProgress: 100,
        pledgeMax: 200,
        categories: ["Platform"],
        social: 'https://twitter.com/pledgio_xyz',
        socialHandle: 'pledgio_xyz',
        owner: 'addr1qydhdwlaq26ztc2l4zzmss745mk6s80yvlj3u7gpja849xhxr4qlnymdwdx325yt7t26h8qv4gkvhmul4l3mclzayrnqgmk7as',
        overview: "Pledgio is an innovative crowdfunding platform that leverages the security of the Cardano blockchain. Here, ideas transform into reality with the support of a community that validates and funds them. To demonstrate our Proof-of-Concept and gauge community interest in our platform, the first project on Pledgio is... Pledgio itself. Enjoy this ‘stripped-backed’ showcase, experiencing first-hand how to navigate Pledgio as a community member and potential pledger.",
        about: "No products, no case for Cardano. The Cardano community is thriving, witnessing a surge of projects in search for their first leg up. There are countless opportunities for building, creating, and innovating. Ensuring that builders have access to funding is key for individuals and businesses to seize these opportunities. Crowd-funding on Cardano has the potential to unlock economic opportunities, driving growth and innovation within the ecosystem. With verified community support of Pledgio - through this POC - we’ll commence our journey to build this platform into another great funding solution for the Cardano network.",        
        timespan: '30',
        id: 1
    }

];