import styled from "styled-components";
import menuicon from "../images/mobile-menu.png";
import { useState } from "react";
import { Link } from 'react-router-dom';
import { useTheme } from "../ThemeContext";
import ButtonPrimary from './button.jsx';
import Wallets from "../wallets.jsx";
import { motion } from "framer-motion";


const MenuButton  = styled(motion.button)`
    display: none;
    @media (max-width: 768px) {
        display: block;
        width: 2.2rem;
        height: 2.2rem;
        background-color: transparent;
        border: none;
        background-image: url(${menuicon});
        background-size: cover;
        z-index: 100;
        cursor: pointer;
        
    }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    background-color: ${({ theme }) => theme.background};
    width: 95vw;
    height: 90vh;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const MenuLinksContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 3.3rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;

`;

const CloseButton = styled(motion.button)`
    position: absolute;
    top: 3%;
    left: 3%;
    background-color: transparent;
    border: none;
    font-size: 2.2rem;
    cursor: pointer;
    font-family: 'FredokaMedium';
    color: ${({ theme }) => theme.color};
`;

const MenuLink = styled(Link)`
    color: ${({ theme }) => theme.color};   
    text-decoration: none;
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1.5rem, 2rem);
    font-weight: 400;
    letter-spacing: 0.07rem; 
    opacity: 0.9;
    transition: color 0.3s ease, color 0.3s ease;
    &:hover {
        color: var(--pledgio-peach);
    }

`;

const WalletsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
  
`;


const MobileMenu = () => {
    
    const [isOpen, setIsOpen] = useState(false);    

    const toggleMenu = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const [showWallets, setShowWallets] = useState(false);

    const { theme } = useTheme();

    const toggleWalletsOverlay = () => {
        setShowWallets((prevShowWallets) => !prevShowWallets);
    };

    const closeMenu = () => {   
        setIsOpen(false);
        setShowWallets(false);
    }


return (
    <>
    <MenuButton
    initial={{ opacity: 0, scale: 0.9 }} 
    animate={{ opacity: 0.9, scale: 1 }} 
    transition={{ duration: 1, delay: 0.2 }}  
    onClick={toggleMenu} 
    isOpen={isOpen} 
    theme={theme} />
    {isOpen && (
        <Overlay theme={theme} >
            <CloseButton
            initial={{ opacity: 0, scale: 0.9 }} 
            animate={{ opacity: 0.9, scale: 1 }} 
            transition={{ duration: 1, delay: 0.2 }} 
            onClick={closeMenu} 
            theme={theme}>X</CloseButton>
            <MenuLinksContainer>
                {showWallets ? (
                    <WalletsContainer>
                        {Wallets.map((wallet, index) => (
                    <ButtonPrimary variant="secondary" fontSize={'clamp(1rem, 1.1vw, 1.7rem)'} >{wallet}</ButtonPrimary>
                ))}
                    </WalletsContainer>
                ) : (
                    <>
                <MenuLink theme={theme} onClick={toggleWalletsOverlay} >Connect Wallet</MenuLink>
                <MenuLink theme={theme} onClick={closeMenu} to="/how-it-works">How it works</MenuLink>
                <MenuLink theme={theme} onClick={closeMenu} to="/projects">Explore Projects</MenuLink>
                <MenuLink theme={theme} onClick={closeMenu} to="/create-project">Create Project</MenuLink>
                <MenuLink theme={theme} onClick={closeMenu} to="/about">About</MenuLink>
                </>
                )}
            </MenuLinksContainer>
        </Overlay>
    )}
    </>
);

};


export default MobileMenu;
