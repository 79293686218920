import styled from 'styled-components';
import { motion } from 'framer-motion';
import ButtonComponent from "../components/button";


const CardContainer = styled(motion.div)`
width: 100%;
height: 45vh;
outline: 2px solid var(--pledgio-peach);
border-radius: 20px;
overflow: hidden;
margin: 0 auto;
padding: 5rem;
background-color: black;
display: flex;
flex-direction: column;
justify-content: space-between;
gap: 2rem;
align-items: center;
cursor: pointer;
&:hover {
    background-color: var(--pledgio-peach);
    H2 {
        color: black;
    }
    button {
        background-color: black;
        color: var(--pledgio-peach);
    }
    img {
        filter: invert(1);
    }
}
@media (max-width: 768px) {
    flex-direction: row;
    width: 90%;
    height: auto;
    text-align: left;
    padding: 2rem 1rem;
    justify-content: space-between;
    gap: 0.3rem;
    &:hover {
        background-color: black;
        H2 {
            color: white;
        }
        button {
            background-color: var(--pledgio-peach);
            color: black;
        }
        img {
            filter: invert(0);
        }
    
}

`;


const ImageContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 8vw;
height: auto;
margin: 0 auto;
contain: content;
@media (max-width: 768px) {
    width: 150px;
    height: auto;
}
`;



const Image = styled(motion.img)`
width: 100%;
height: auto;
margin: 0 auto;
display: block;
padding: 1rem;
@media (max-width: 768px) {
    padding: 0rem;
    width: 100%;
    height: auto;
}
`;

const DescriptionContainer = styled.div`
width: 100%;
`;




const H2 = styled(motion.h2)`
    font-family: 'FredokaMedium';
    font-size: clamp(1rem, 1vw, 2rem);
    letter-spacing: 0.1rem;
    font-weight: 100;
    color: white;
    opacity: 0.9;
    line-height: 1;

    @media (max-width: 768px) {
        font-size: clamp(0.8rem, 2vw, 2rem);
        letter-spacing: 0rem;
        line-height: 1;
        padding: 0.4rem 1rem;
    }
`;



const PledgeTypeCard = ({ image, title, description, amount, onClick }) => {

    const handleButtonClick = () => {
        onClick({ image, title, description, amount });
    };

    const label = amount === 0 ? 'FREE' : `${amount}ADA`;

    return (
        <CardContainer
        initial={{ opacity: 0, scale: 0.7 }} 
        animate={{ opacity: 0.9, scale: 1 }} 
        transition={{ duration: 1, delay: 0 }}
        onClick={handleButtonClick} >
            {image && 
            <ImageContainer>
                <Image src={image} alt={title} />
            </ImageContainer>
            }   
            <DescriptionContainer>
                <H2>{description}</H2>
            </DescriptionContainer>
                <ButtonComponent onClick={handleButtonClick} fontSize={'clamp(1rem, 1vw, 1.7rem)'}>{label}</ButtonComponent>
        </CardContainer>
    );
};


export default PledgeTypeCard;