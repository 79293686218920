
const Wallets = [
    "Nami",
    "Eternl",
    "Gero",
    "Lace",
    "Yoroi",
    "Flint",
    "Typhon",
    "Vespr"
]

export default Wallets;